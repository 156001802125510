import * as React from "react"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import {
  AiFillLinkedin,
  AiFillYoutube,
  AiOutlineInstagram,
} from "react-icons/ai"
import { FaFacebook } from "react-icons/fa"
import * as config from "../../util/config"
import bbbRating from "../../assets/images/bbb-rating.webp"

type IFooterProps = {
  blok?: {
    includeQRCode: boolean
  }
  noBlok?: boolean
}
const currentYear = new Date().getFullYear()

export default function Footer(props: IFooterProps) {
  const { blok: { includeQRCode = false } = {} } = props

  const app_host = config.getAppHost()

  return (
    <footer className="w-full">
      <article className="header-padding bg-blue16 divide-y-2">
        <section
          className="
            w-full flex flex-col xl:flex-row md:justify-between pb-10 gap-8"
        >
          <div className="w-full grid grid-cols-2 md:flex flex-col md:flex-row justify-between px-4 gap-8">
            <div className="md:w-2/5">
              <p className="text-md text-blue5 font-bold mb-6">
                Shipping Customers
              </p>
              <nav className="w-full text-md grid md:grid-cols-2 gap-3">
                <div className="flex flex-col gap-3">
                  <Link href="/pet-transportation">Pets and Animals</Link>
                  <Link href="/pet-shipping/all-cities">
                    <b>- </b>All Cities
                  </Link>
                </div>
                <div className="flex flex-col gap-3">
                  <Link href="/dog-transportation">Dog Transport</Link>
                  <Link href={`/dog-shipping/all-breeds`} prefetch={false}>
                    <b>- </b>All Dog Breeds
                  </Link>
                </div>
                <div className="flex flex-col gap-3">
                  <Link href="/cat-transportation">Cat Transport</Link>
                  <Link
                    href={`/cat-transportation/all-breeds`}
                    prefetch={false}
                  >
                    <b>- </b>All Cat Breeds
                  </Link>
                </div>
                <div className="flex flex-col gap-3">
                  <Link href="/military-moves">PCS Pet Relocation</Link>
                  <Link href={`/military-moves/all-bases`} prefetch={false}>
                    <b>- </b>All Bases
                  </Link>
                </div>
                <Link href="/vehicle-transportation">Vehicle Shipping</Link>
                <Link href="/motorcycle-transportation">
                  Motorcycle Shipping
                </Link>
                <div className="flex flex-col gap-3">
                  <Link href="/boat-transportation" prefetch={false}>
                    Boat Transport
                  </Link>
                  <Link href="/boat-transportation/all-cities" prefetch={false}>
                    <b>- </b>All Cities
                  </Link>
                </div>
                <div className="flex flex-col gap-3">
                  <Link href="/rv-transport">RV Transport</Link>
                  <Link href="/apartment-moves">Apartment Moves</Link>
                </div>
                <Link href="/shipping-furniture">Furniture Shipping</Link>
                <Link href="/equipment-shipping">Heavy Equipment</Link>
                <Link href="/shipping-large-items">Large Items</Link>
                <Link href="/pallet-shipping">Pallets</Link>
                <Link href="/freight-shipping-quotes">Freight</Link>
                <Link href="/package-delivery">Package Delivery</Link>
                <Link href="/shipments/new/others">All Other Items</Link>
                <Link href={"/cost-to-ship"}>Cost To Ship - All Cities</Link>
              </nav>
              <div className="md:hidden w-full mt-8">
                <p className="text-md text-blue5 font-bold mb-6">
                  Connect With Us
                </p>
                <nav className="text-md flex flex-col flex-wrap text-blue7 gap-3">
                  <Link
                    href="https://help.citizenshipper.com/hc/en-us/requests/new"
                    target="_blank"
                  >
                    24/7 Customer Support
                  </Link>
                  <ul className="pl-0 space-y-3">
                    <li>
                      <Link
                        className="flex"
                        href="https://www.linkedin.com/company/citizenshipper"
                      >
                        <AiFillLinkedin className="text-3xl text-blue5 mr-2" />
                        LinkedIn
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="flex"
                        href="https://www.facebook.com/CitizenShipperLLC/"
                      >
                        <FaFacebook className="text-[29px] text-blue5 mr-2" />
                        Facebook
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="flex"
                        href="https://www.instagram.com/citizenshipper/"
                      >
                        <AiOutlineInstagram className="text-3xl text-blue5 mr-2" />
                        Instagram
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="flex"
                        href="https://www.youtube.com/@citizenshipper"
                      >
                        <AiFillYoutube className="text-3xl text-blue5 mr-2" />
                        Youtube
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="md:w-3/5 flex flex-col md:flex-row justify-between gap-8">
              <div className="w-full">
                <p className="text-md text-blue5 font-bold mb-6">
                  For Breeders
                </p>
                <nav className="text-md flex flex-col flex-wrap gap-3 mb-16">
                  <Link href="/breeders">Puppy Transport</Link>
                  <Link href="https://citizenshipper.com/blog/category/for-breeders/">
                    Breeder Resources
                  </Link>
                </nav>
                <p className="text-md text-blue5 font-bold mb-6">For Drivers</p>
                <nav className="text-md md:max-h-64 flex flex-col gap-3">
                  <Link
                    className="block"
                    href="https://citizenshipper.com/animal-transporter-guide"
                  >
                    Become an Animal Transporter
                  </Link>
                  <Link
                    className="block"
                    href="https://citizenshipper.com/new-drivers"
                  >
                    Get Paid to Drive
                  </Link>
                  <Link
                    className="block"
                    href="https://citizenshipper.com/motorcycle-carriers"
                  >
                    Become a Motorcycle Transporter
                  </Link>
                  <Link
                    className="block"
                    href="https://citizenshipper.com/blog/category/for-transporters/"
                  >
                    Driver Resources
                  </Link>
                  <a className="block" href={`${app_host}/register`}>
                    Sign up
                  </a>
                </nav>
              </div>

              <div className="w-full">
                <p className="text-md text-blue5 font-bold mb-6">Resources</p>
                <nav className="text-md grid grid-col-1 text-blue7 gap-3">
                  <Link href="/how-it-works">How it Works</Link>
                  <Link href="https://help.citizenshipper.com/hc/en-us">
                    Help Center and Support
                  </Link>
                  <Link href="https://help.citizenshipper.com/hc/en-us/articles/204192449-Refund-Policy">
                    Refund Policy
                  </Link>
                  <Link href="https://citizenshipper.com/blog/">Blog</Link>
                  <Link href="/about-us">About Us</Link>

                  <Link href="/affiliate-program">
                    Become an Affiliate Partner
                  </Link>
                  <Link href="https://citizenshipper.com/referral-program">
                    Join Our Referral Program
                  </Link>
                  <Link href="https://citizenshipper.com/partners">
                    Partner with Us
                  </Link>
                  <Link href="https://help.citizenshipper.com/hc/en-us/categories/200277749-Terms-of-Service-Agreements">
                    Terms and Conditions
                  </Link>
                  <Link href="https://help.citizenshipper.com/hc/en-us/articles/204865815-Privacy-Policy">
                    Privacy Policy
                  </Link>
                  <Link href="/faq">Frequently Asked Questions</Link>
                  <Link href="/sitemap">Sitemap</Link>
                </nav>
              </div>
              <div className="hidden md:block w-full">
                <p className="text-md text-blue5 font-bold mb-8">
                  Connect With Us
                </p>
                <nav className="text-md flex flex-col flex-wrap text-blue7 gap-3">
                  <Link
                    href="https://help.citizenshipper.com/hc/en-us/requests/new"
                    target="_blank"
                  >
                    24/7 Customer Support
                  </Link>
                  <ul className="pl-0 space-y-3">
                    <li>
                      <Link
                        className="flex"
                        href="https://www.linkedin.com/company/citizenshipper"
                      >
                        <AiFillLinkedin className="text-3xl text-blue5 mr-2" />
                        LinkedIn
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="flex"
                        href="https://www.facebook.com/CitizenShipperLLC/"
                      >
                        <FaFacebook className="text-[29px] text-blue5 mr-2" />
                        Facebook
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="flex"
                        href="https://www.instagram.com/citizenshipper/"
                      >
                        <AiOutlineInstagram className="text-3xl text-blue5 mr-2" />
                        Instagram
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="flex"
                        href="https://www.youtube.com/@citizenshipper"
                      >
                        <AiFillYoutube className="text-3xl text-blue5 mr-2" />
                        Youtube
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
      </article>
      <section className="header-padding w-full py-8 gap-8 divide-y-2">
        <section className="flex flex-col md:flex-row justify-between items-start gap-8 py-4 relative">
          <div className="gap-8">
            <p className="font-bold">Contact Us</p>
            <p className="text-blue-700">
              Submit a{" "}
              <Link
                href="https://help.citizenshipper.com/hc/en-us/requests/new"
                target="_blank"
              >
                customer support request here
              </Link>
            </p>
          </div>
          {includeQRCode === true ? <QRCode /> : null}
          <p>
            701 Tillery Street Unit 12-1249
            <br />
            Austin, Texas, 78702
          </p>
        </section>
        <section className="flex flex-col md:flex-row justify-between gap-8 py-4">
          <div>
            <div className="flex gap-8">
              <Link href="https://help.citizenshipper.com/hc/en-us/categories/200277749-Terms-of-Service-Agreements">
                Terms and Conditions
              </Link>
              <Link href="https://help.citizenshipper.com/hc/en-us/articles/204865815-Privacy-Policy">
                Privacy Policy
              </Link>
            </div>
            <p>&#169; {currentYear} CitizenShipper. All Rights Reserved.</p>
          </div>
          <div className="flex flex-row justify-between items-center gap-2 md:gap-10 ">
            <Image
              src="/icons/powered-by-stripe.webp"
              alt="CitizenShipper uses Stripe for secure payments."
              width="150"
              height="30"
            />
            <a
              href="https://www.bbb.org/us/tx/austin/profile/delivery-service/citizen-shipper-0825-1000131839"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={bbbRating.src}
                alt="CitizenShipper is a BBB Accredited Business."
                width="135"
                height="40"
                loading="lazy"
              />
            </a>
          </div>
        </section>
      </section>
    </footer>
  )
}

const codes: Record<string, string> = {
  "/cat-transportation": "dbkpsfejlndhdyqld",
  "/pet-transportation": "pwjztrgfnmjsurhf ",
}

const QRCode = () => {
  const router = useRouter()
  const path = router.asPath
  const code = codes[path]

  const [render, setRender] = React.useState(false)

  React.useEffect(() => {
    setRender(true)
  }, [])

  return render === true ? (
    <div
      className="flex flex-col items-center md:absolute md:left-1/2 md:transform md:-translate-1/2 md:top-14"
      id="qr-code-wrapper"
    >
      <p className="text-xs">{code}</p>
    </div>
  ) : null
}
